export enum SortBy {
	Asc = 'asc',
	Desc = 'desc'
}

export enum Modal {
	Delete = 'DELETE',
	Status = 'STATUS',
	Filter = 'FILTER',
	Logout = 'LOGOUT',
	Upload = 'UPLOAD',
	ReachedLimit = 'REACH_LIMIT',
	View = 'VIEW',
	Create = 'CREATE',
	Accept = 'ACCEPT',
	Reject = 'REJECT',
	Clone = 'CLONE'
}

export enum Status {
	Active = 'active',
	Inactive = 'inactive',
	Lock = 'lock',
	Unlock = 'unlock',
	Approve = 'Approve',
	Reject = 'Reject'
}

export enum Role {
	Admin = 'Admin',
	SuperAdmin = 'Super Admin',
	OEM = 'OEM',
	Master = 'Master',
	User = 'User',
	Dealer = 'Dealership User',
	Special_previledges = 'Special Priveledges'
}

export enum FilterParams {
	Year = 'year',
	Month = 'month',
	dealerCode = 'dealer_code',
	region = 'region'
}
