import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

export const API_CONFIG = {
	login: 'auth/login',
	forgotPassword: 'auth/forgot-password',
	resetPassword: 'auth/reset-password',
	verifyResetPasswordEmail: 'auth/verify-reset-password-email',
	changePassword: 'auth/change-password',
	verifyAccount: 'auth/verify-email',
	resendVerificationEmail: 'auth/resend-verification-email',
	user: 'user',
	userDetails: 'user/account',
	userStatus: 'user/status',
	userProfile: 'user/profile',
	userList: 'user/list',
	dropDownOption: 'user/dropdown-list',
	presignedUrl: 'user/presigned-url/upload',
	dealer: 'dealer',
	dealerList: 'dealer/list',
	dealerView: 'dealer/view',
	dealerStatus: 'dealer/status',
	financialStatement: 'fs',
	downloadFSExcel: 'fs/report',
	reportList: 'reports/list',
	closedData: 'fs/closed-data',
	lockFS: 'fs/lock-fs',
	submissionList: 'fs/month-wise-summary',
	fsHeader: 'fs/header',
	reports: 'reports',
	changeRequestList: 'admin/pending-xml',
	changeRequestStatus: 'admin/pending-xml/status',
	notification: 'notification/list',
	readNotification: 'notification/read',
	markAllAsRead: 'notification/mark-all-read',
	visit: 'visit-management/visit',
	agenda: 'visit-management/agenda',
	agendaList: 'visit-management/agenda/list',
	actionItem: 'visit-management/action-item',
	actionItemStatus: 'visit-management/action-item/status',
	agendaAction: 'visit-management/visit/agenda',
	textEditor: 'data-management/text-editor',
	visitAgenda: 'visit-management/visit-agenda',
	createBusinessPlan: 'business-plan',
	businessPlanFacilitator: 'business-plan/facilitators',
	businessPlanComments: 'business-plan/comment',
	KPIDetails: 'business-plan/kpi',
	businessPlanDetails: 'business-plan/view',
	businessPlanAnnualRatio: 'business-plan/annual-ratio',
	businessPlanApproval: 'business-plan/submit-for-approval',
	businessPlanHistory: 'business-plan/history',
	downloadBusinessPlanPDF: 'business-plan/pdf',
	totalExpenses: 'business-plan/view/total-expenses',
	totalOperations: 'business-plan/view/total-operations',
	signatureUpload: 'user/upload-file',
	visitSummary: 'visit-management/visit-summary',
	powerBIAccessToken: 'reports/microsoft/token',
	actionItemSummary: 'visit-management/action-item-summary',
	searchFSKPIs: 'fs/accounts',
	cloneVisit: 'visit-management/copyvisit'
};

export const getUrl = (url: string, params: any = {}): string => {
	Object.keys(params).forEach((key) => (params[key] == null || params[key] === '') && delete params[key]);
	let urlString = url;
	if (params && !isEmpty(params)) {
		urlString += `?${queryString.stringify(params)}`;
	}
	return urlString;
};
