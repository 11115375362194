/* tslint:disable */

const fr = {
	userList: 'Liste des utilisateurs',
	financialStatement: 'État Financier',
	filter: 'Filtre',
	search: 'Recherche',
	addUser: 'Ajouter un utilisateur',
	userFilter: "Filtre d'utilisateur",
	status: 'Statut',
	selectStatus: 'Sélectionner le statut',
	apply: 'Appliquer',
	reset: 'Réinitialiser',
	dealerCode: 'Code du concessionnaire',
	dealerName: 'Nom du revendeur',
	actions: 'Actes',
	active: 'Actif',
	inactive: 'Inactif',
	areYouSureToInactiveActive: "Êtes-vous sûr de vouloir désactiver/activer l'utilisateur du nom d'utilisateur ?",
	view: 'Voir',
	edit: 'Modifier',
	delete: 'Supprimer',
	usersProfile: "Profil de l'utilisateur",
	phoneNumber: 'Numéro de téléphone',
	enterPhoneNumber: 'Entrez le numéro de téléphone',
	areYouSureToDeleteUser: "Êtes-vous sûr de vouloir supprimer l'utilisateur nommé username ?",
	dealerFilter: 'Filtre de revendeur',
	region: 'Région',
	selectRegion: 'Sélectionner la région',
	pma: 'PMA',
	phevRetailer: 'Détaillant de véhicules rechargeables',
	territory: 'Territoire',
	activated: 'Activé',
	dealerPrincipal: 'Directeur du concessionnaire',
	generalManager: 'Directeur général',
	prov: 'Prov.',
	dms: 'DMS',
	city: 'Ville',
	address: 'Adresse',
	pc: 'PC',
	website: 'Site web',
	legalEntityName: "Nom de l'entité juridique",
	dealerPrincipalEmail: 'Courriel du concessionnaire principal',
	generalManagerEmail: 'Courriel du directeur général',
	language: 'Langue',
	regionClassification: 'Classement des régions',
	pvClassification: 'Classification PV',
	dealerCodeValidation: 'Le code du concessionnaire doit comporter exactement cinq chiffres.',
	firstNameValidation: 'Le prénom ne peut pas dépasser 50 caractères.',
	jobTitleRequired: 'Le titre du poste est requis.',
	validWebsiteUrl: 'Veuillez entrer une URL de site web valide.',
	faxValidation:
		'Le numéro de fax ne peut contenir que des chiffres, des espaces, des parenthèses, des tirets et des signes plus.',
	commentsValidation: 'Les commentaires ne peuvent pas dépasser 300 caractères.',
	planNameMinLength: 'Le nom du plan doit comporter au moins 2 caractères.',
	planNameMaxLength: 'Le nom du plan ne peut pas dépasser 100 caractères.',
	endDateValidation:
		"La date et l'heure de fin ne doivent pas être antérieures ou égales à la date et l'heure de début.",
	fileSizeValidation:
		'La taille du fichier dépasse la limite autorisée. Veuillez télécharger un fichier de 5 Mo maximum.',
	phoneNumberValidation:
		'Le numéro de téléphone doit contenir uniquement des chiffres, des parenthèses, des tirets et des signes plus, et doit comporter entre 8 et 12 caractères.',
	passwordValidation:
		'Le mot de passe doit comporter entre 8 et 16 caractères, incluant au moins une lettre majuscule, une lettre minuscule, un caractère spécial et un chiffre.',
	accountVerificationFailed:
		"La vérification de votre compte a échoué. Cliquez sur le bouton ci-dessous pour renvoyer l'email de vérification.",
	updateDealer: 'Mettre à jour le revendeur',
	dealersProfile: 'Profil du concessionnaire',
	noDealerFound: 'Aucun concessionnaire trouvé',
	monthWiseFinancialStatement: 'État financier mensuel',
	noMonthWiseFinancialStatementFound: 'Aucun état financier mensuel trouvé',
	lastUpdatedOn: 'Dernière mise à jour le',
	mitsubishiCanada: 'Mitsubishi Canada',
	page: 'page',
	assetsLiabilities: 'Actifs et passifs',
	salesPL: 'Ventes - P&L',
	aftersalesPL: 'Aftersales - P&L',
	miscellaneousIndicators: 'Indicateurs divers',
	noFinancialStatementFound: 'Aucun état financier trouvé',
	month: 'Mois',
	totalOpExpense: 'Total des dépenses opérationnelles',
	changeRequest: 'Demande de modification',
	submittedOn: 'Soumis le',
	monthYearToBeUpdated: 'Mois/Année à mettre à jour',
	approve: 'Approuver',
	reject: 'Rejeter',
	submissions: 'Soumissions',
	startDateToSubmitFS: 'Date de début pour soumettre FS',
	lockDateToSubmitFS: 'Date de verrouillage pour soumettre FS',
	financialStatementSubmissionStatus: "Statut de la soumission de l'état financier",
	actionPlan: "Plan d'action",
	confirmAction: "Confirmer l'action",
	taskId: 'ID de la tâche',
	commentHistory: 'Historique des commentaires',
	viewAction: "Voir l'action",
	viewAgenda: "Voir l'avis",
	noActionItemFound: "Aucun plan d'action trouvé",
	pending: 'En attente',
	clearAll: 'Tout effacer',
	signOut: 'Se déconnecter',
	welcome: 'Bienvenue',
	close: 'Fermer',
	areYouSureTo: 'Etes-vous sûr de vouloir',
	user: 'utilisateur',
	dealer: 'Concessionnaire',
	firstName: 'Prénom',
	lastName: 'Nom de famille',
	email: 'E-mail',
	jobTitle: "Titre d'emploi",
	verified: 'Vérifié',
	uploadImage: 'Télécharger une image',
	role: 'Rôle',
	select: 'Sélectionnez',
	confirm: 'Confirmer',
	cancel: 'Annuler',
	update: 'Mise à jour',
	add: 'Ajouter',
	enter: 'Entrer',
	noOptionFound: 'Aucune option trouvée',
	no: 'No',
	found: 'trouvée',
	users: 'Utilisateurs',
	dealers: 'Concessionnaires',
	closeData: 'Fermer les données',
	reports: 'Rapports',
	visitManagement: 'Console de réunion',
	businessPlan: "Plan d'affaires",
	notifications: 'Notifications',
	myProfile: 'Mon profil',
	changePassword: 'Changer le mot de passe',
	logout: 'Déconnexion',
	enterYour: 'Entrez votre',
	oldPassword: 'Ancien mot de passe',
	newPassword: 'Nouveau mot de passe',
	confirmPassword: 'Confirmez le mot de passe',
	phone: 'Téléphone',
	fax: 'Fax',
	generalMangerGM: 'Directeur général',
	copied: 'Copié',
	all: 'Tous',
	submitted: 'Soumis',
	reSubmitted: 'Soumis à nouveau',
	downloaded: 'Téléchargé',
	visit: 'Réunion',
	markAllAsRead: 'Marquer tout comme lu',
	notificationsNotAvailable: 'Notifications non disponibles.',
	showMore: 'Afficher plus',
	yayYouHaveSeenItAll: 'Ouais! Tu as tout vu',
	selectDealer: 'Sélectionnez un revendeur',
	downloadFS: 'Télécharger FS',
	generatingFS: `Génération d'états financiers pour`,
	pleaseHoldOn: 'Veuillez patienter',
	year: 'Année',
	fsContainsNegativeVal: 'FS contient des valeurs négatives',
	salesGrossProfit: 'Ventes - Bénéfice brut',
	afterSalesGrossProfit: 'Après-vente - Bénéfice brut',
	yes: 'Oui',
	request: 'Demande',
	lock: 'Verrouiller FS',
	unlock: 'Déverrouiller FS',
	agendaAndAction: 'Liste de contrôle et plan d’action',
	publishAndShare: 'Publier et partager',
	visitCode: 'Code de réunion',
	visitDate: 'Date de la réunion',
	createdBy: 'Créé par',
	conductedBy: 'Réalisé par',
	department: 'Domaine Fonctionnel',
	subDepartment: 'Sous Domaine Fonctionnel',
	visitType: 'Mode réunion',
	agendaItems: 'Liste de contrôle de révision',
	actionItems: "Plan d'action",
	addAVisit: 'Ajouter une réunion',
	visitFilter: 'Filtre de réunion',
	visitStartEndDate: 'Date de début / date de fin de la réunion',
	participants: 'Participants',
	agendaItem: 'Liste de contrôle de révision',
	actionItem: "Plan d'action",
	agenda: 'revoir',
	addNewAgenda: 'Ajouter un nouvel avis',
	addAgenda: 'AJOUTER UN AVIS',
	noAgendaItemFound: "Aucune liste de contrôle d'évaluation trouvée",
	selectedAgendaItems: "Liste de contrôle d'examen sélectionnée",
	removeMonoAgenda: 'SUPPRIMER LA REVUE MONO',
	title: 'Titre',
	id: 'IDENTIFIANT',
	assignTo: 'Attribué à',
	dueDate: `Date d'échéance`,
	addActionItems: "Ajouter un plan d'action",
	areYouSureYouWantToDelete: 'Êtes-vous sûr de vouloir supprimer',
	save: 'Sauvegarder',
	saveAndNext: 'Enregistrer et suivant',
	next: 'Suivant',
	visitConductedBy: 'Réunion dirigée par',
	startDateTime: 'Date et heure de début de la réunion',
	endDateTime: 'Date et heure de fin de la réunion',
	addParticipants: 'Ajouter des participants',
	selectDate: 'Sélectionnez une date',
	selectTime: `Sélectionnez l'heure`,
	searchParticipant: 'Rechercher un participant',
	noParticipants: `Il n'y a aucun participant.`,
	comment: 'Commentaire',
	internalComment: 'Commentaire interne',
	writeYourInternalMsg: 'Écrivez ici votre commentaire interne',
	dashboard: 'Tableau de bord',
	noReportFound: 'Aucun rapport trouvé',
	new: 'Nouveau',
	parts: 'Pièces',
	totalOperations: 'Total des opérations',
	used: 'Utilisé',
	service: 'Service',
	bodyShop: 'Atelier de carrosserie',
	balanceSheet: 'Bilan',
	workingCapital: 'Fonds de roulement',
	powerBIReport: 'Rapport PowerBI',
	bizDrivePoweredByAutoRuptiv: 'BizDrive. Propulsé par AutoRuptiv',
	createNewPlan: 'Créer un nouveau forfait',
	category: 'Catégorie',
	commentForRejection: 'Commentaire de rejet',
	typeYourFeedbackOrReasonForRejection: 'Saisissez vos commentaires ou le motif du rejet...',
	planID: 'Identifiant du forfait',
	planName: 'Nom du régime',
	createdDate: 'Date de création',
	submission: 'Soumission',
	downloadBusinessPlanPDF: "Télécharger le plan d'affaires PDF",
	approved: 'Approuvé',
	rejected: 'Rejeté',
	pendingApproval: "En attente d'approbation",
	draft: 'Brouillon',
	range: 'Gamme',
	PV: 'PV',
	PVRegionDealer: 'PV/Région/Concessionnaire',
	noCommentsFound: 'Aucun commentaire trouvé',
	totalExpenses: 'Dépenses totales',
	KPIDescription: 'KPI Description',
	actual: 'Réel',
	target: 'Cible',
	history: 'Histoire',
	submitForApproval: 'Soumettre pour approbation',
	businessPlanDetails: "détails du plan d'affaires",
	details: 'Détails',
	create: 'Créer',
	generate: 'Générer',
	previous: 'Précédent',
	selectBaseline: 'Sélectionnez la ligne de base',
	chooseOneOption: 'Choisissez une option',
	dealerOrRegionOrPV: 'Concessionnaire ou région ou PV',
	businessPlanForYear: "Plan d'affaires pour l'année",
	isAlreadyCreatedDoYouWantToViewIt: 'est déjà créé. Voulez-vous le voir',
	option: 'option',
	facilitator: 'Animateurs',
	by: 'Par',
	on: 'sur',
	noHistoryFound: 'Aucun historique trouvé',
	at: 'à',
	annualTarget: 'Objectif annuel',
	writeYourCommentHere: 'Écrivez votre commentaire ici',
	imageISUploading: `L'image est en cours de téléchargement`,
	assign: 'Attribuer',
	agendaItemValidation: "Le nom de l'avis ne peut pas dépasser 200 caractères",
	deleteActionItem: 'Êtes-vous sûr de vouloir supprimer ce plan d’action ?',
	assignee: 'Cessionnaire',
	here: 'Ici',
	tagUser: `Marquer l'utilisateur`,
	replies: 'Réponses',
	reply: 'Répondre',
	closeThread: 'Fermer le fil de discussion',
	hide: 'Cacher',
	priority: 'Priorité',
	uploadedFiles: 'Fichiers téléchargés',
	name: 'Nom',
	uploadActionDocs: `Télécharger des documents d'action`,
	noFileChosen: 'Aucun fichier choisi',
	deleteAgendaItem: 'Êtes-vous sûr de vouloir supprimer cet avis ?',
	areYouSureRemoveAgendaItem: "Êtes-vous sûr de vouloir supprimer cette liste de contrôle d'avis ?",
	send: 'Envoyer',
	uploadFiles: 'Télécharger des fichiers',
	high: 'Haut',
	medium: 'Moyen',
	low: 'Faible',
	roleInformation: 'Informations sur le rôle',
	online: 'En ligne',
	call: 'Appel',
	onsite: 'Sur place',
	toDo: 'Faire',
	inProgress: 'En cours',
	completed: 'Complété',
	fieldRequired: 'Ce champ est obligatoire',
	invalidEmailAddress: 'Adresse email invalide',
	isRequired: 'est requis',
	charValidation50: 'ne peut pas dépasser 50 caractères',
	charValidation100: 'ne peut pas dépasser 100 caractères',
	passwordNotMatched: 'Le mot de passe ne correspond pas',
	password: 'Mot de passe',
	actionDocument: `Document d'action`,
	duplicateFileError: 'Les fichiers en double ne sont pas autorisés.',
	maxFileUploadValidation5: `Vous ne pouvez télécharger qu'un maximum de 5 fichiers à la fois.`,
	maxFileUploadValidation10: `Vous ne pouvez télécharger qu'un maximum de 10 fichiers.`,
	fileLimitValidation: `La taille du fichier dépasse la limite autorisée. Veuillez télécharger un fichier ne dépassant pas 1 Mo.`,
	invalidImgTypeMsg: `Fichier non valide. Veuillez télécharger un fichier avec l'une des extensions suivantes: .jpeg, .jpg, .png, .webp`,
	noBusinessPlanFound: `Aucun plan d'affaires trouvé`,
	areYouSureYouWantToLogout: 'Êtes-vous sûr de vouloir vous déconnecter ?',
	visitSummaryReport: 'Rapport sommaire de la réunion',
	signature: 'Signature',
	clear: 'Claire',
	trim: 'Garniture',
	pendingSignature: 'En attente de signature',
	saveYourLatestChanges: 'Vous avez des modifications non enregistrées.',
	doYouWantSaveChanges: 'Souhaitez-vous enregistrer vos modifications avant de quitter cette page ?',
	firstNameRequired: 'Le prénom est obligatoire',
	lastNameRequired: 'Le nom de famille est obligatoire',
	selectRole: 'Sélectionnez un rôle',
	from: 'Depuis',
	to: 'À',
	atlantic: 'Atlantique',
	bc: 'BC',
	mitsubishi: 'Mitsubishi',
	ontario: 'Ontario',
	quebec: 'Québec',
	western: 'Occidentale',
	total: 'Totale',
	discard: 'Jeter',
	startDateValidation: 'La date de début doit être inférieure ou égale à la date de fin.',
	actionItemReport: "Rapport sur le plan d'action",
	noDataAvailable: 'Aucune Donnée Disponible',
	totalVisit: 'Réunion totale',
	totalActionItems: "Plan d'action global",
	lookup: 'Recherche de compte',
	searchAccIdOrKPI: 'Recherche par identifiant de compte ou KPI',
	helpNotAvailable: 'Aucun KPI trouvé',
	dealerType: 'Taper',
	prospect: 'Perspective',
	addNewDealerOrProspect: 'Ajouter un nouveau Concessionnaire / Prospect',
	sales: 'Ventes',
	afterSales: 'Après-vente',
	general: 'Général',
	marketing: 'Commercialisation',
	networkDevelopment: 'Développement de réseau',
	training: 'Entraînement',
	others: 'Autres',
	followUpDate: 'Date de suivi',
	visitDetails: 'Détails de la réunion',
	copyVisit: 'Copier la réunion',
	copy: 'copier',
	this: 'cette',
	uploadAgendaDocs: 'Télécharger des documents de révision',
	january: 'Janv.',
	february: 'Févr.',
	march: 'Mars.',
	april: 'Avr.',
	may: 'Mai',
	june: 'Juin.',
	july: 'Juil.',
	august: 'Août',
	september: 'Sept.',
	october: 'Oct.',
	november: 'Nov.',
	december: 'Déc.',
	underConstruction: 'Cette page est actuellement en construction',
	more: 'plus'
};

export default fr;
